import React, { useEffect, useRef } from 'react'
import { Maitree, Nunito_Sans } from 'next/font/google'
import { gsap } from 'gsap'
import Link from 'next/link'
import { SmallTitle } from 'components/SmallTitle'
import { ScrollTopButton } from 'components/ScrollTopButton'
import { StyledFooter } from './Styles';

const maitree = Maitree({
  weight: ['300'],
  subsets: ['latin'],
})

const nunitoSans = Nunito_Sans({
  weight: ['300'],
  subsets: ['latin'],
})

export const Footer = ({ title, links, mail }) => {
  const socials = useRef(null)
  const charsRefs = useRef([])

  const contact = useRef(null)
  const contactShow = useRef([])
  const contactHide = useRef([])

  useEffect(() => {
    if (socials && charsRefs.current.length === 0) {
      import('splitting').then(({ default: Splitting }) => {
        Splitting()
        const showChars = contact.current.querySelectorAll('.show .char')
        const hideChars = contact.current.querySelectorAll('.hide .char')

        contactShow.current.push(showChars)
        contactHide.current.push(hideChars)

        gsap.set(contactHide.current, { y: 5, rotateX: -90, opacity: 0 })

        const tl = gsap.timeline({ paused: true })

        contact.current.addEventListener('mouseover', () => {
          tl.to(contactShow.current, {
            y: -5,
            opacity: 0,
            rotateX: 90,
            stagger: 0.02,
            ease: 'sine.out',
          }).to(
            contactHide.current,
            {
              y: 0,
              rotateX: 0,
              opacity: 1,
              stagger: 0.02,
              ease: 'sine.out',
              onComplete: () => tl.pause()
            },
            '<0.1'
          )

          tl.play()
        })

        contact.current.addEventListener('mouseout', () => {
          tl.reverse()
        })
      })
    }
  })

  return (
    <>
      <ScrollTopButton />
      <StyledFooter>
        <div className='grid grid-cols-12 xl:grid-cols-24 gap-x-2.5'>
          <div className='col-start-2 col-end-12 xl:col-start-3 xl:col-end-23 flex flex-col gap-y-10'>
            <SmallTitle title={title} level={'2'} />
            <div className='flex flex-row flex-wrap justify-between items-end'>
              <div
                ref={socials}
                className={`${maitree.className} flex flex-col items-start socials`}
              >
                {links.map((item, index) => (
                  <Link
                    key={index}
                    aria-label={item.link.title}
                    href={item.link.url}
                    target={item.link.target}
                  >
                    {item.link.title}
                  </Link>
                ))}
              </div>
              <Link
                ref={contact}
                className={`${nunitoSans.className} email`}
                aria-label={mail.title}
                href={mail.url}
              >
                <span data-splitting='chars' className='show text-sm'>
                  {mail.title}
                </span>
                <span data-splitting='chars' className='hide text-sm'>
                  {mail.title}
                </span>
              </Link>
            </div>
          </div>
        </div>
      </StyledFooter>
    </>
  )
}
import React, { useEffect, useRef, useCallback } from 'react'
import gsap from 'gsap'
import { useLenis } from '@studio-freight/react-lenis'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { AiOutlineArrowUp } from 'react-icons/ai'
import { Container, Area, Button } from './Styles';

export const ScrollTopButton = () => {
  const lenis = useLenis(ScrollTrigger.update)
  const container = useRef(null)
  const area = useRef(null)
  const button = useRef(null)

  const animateScrollTopButton = () => {
    gsap.fromTo(
      container.current,
      {
        opacity: 0,
        pointerEvents: 'none',
      },
      {
        opacity: 1,
        pointerEvents: 'all',
        scrollTrigger: {
          trigger: 'footer',
          start: 'top 80%',
          end: 'bottom bottom',
          scrub: 1,
        },
      }
    )

    gsap.fromTo(
      button.current,
      {
        scale: 0,
      },
      {
        scale: 1,
        scrollTrigger: {
          trigger: 'footer',
          start: 'top 80%',
          end: 'bottom bottom',
          scrub: 1,
        },
      }
    )
  }

  const parallaxIt = (e, target, movement) => {
    const boundingRect = area.current.getBoundingClientRect()
    const relX = e.pageX - boundingRect.left
    const relY = e.pageY - boundingRect.top
    const scrollTop = window.scrollY || document.documentElement.scrollTop

    gsap.to(target.current, {
      x: (relX - boundingRect.width / 2) * movement,
      y: (relY - boundingRect.height / 2 - scrollTop) * movement,
      ease: 'power4.out',
      duration: 0.5,
    })
  }

  const callParallax = useCallback((e) => {
    button.current && parallaxIt(e, button, 0.8)
  }, [button])

  const resetParallaxTween = (ref) => {
    gsap.to(ref.current, {
      x: 0,
      y: 0,
      duration: 0.5,
      ease: 'back',
    })
  }

  const resetParallax = useCallback(() => {
    button.current && resetParallaxTween(button)
  }, [button])

  useEffect(() => {
    ScrollTrigger.refresh()

    animateScrollTopButton()

    area.current.addEventListener('mousemove', callParallax)
    area.current.addEventListener('mouseleave', resetParallax)

    if (lenis) button.current.addEventListener('click', () => lenis.scrollTo(0))
  }, [lenis, callParallax, resetParallax])

  return (
    <Container
      ref={container}
      className='hidden md:w-full md:grid md:grid-cols-24 gap-x-2.5'
    >
      <div className='flex justify-center items-end md:col-start-22 md:col-end-23'>
        <Area ref={area}>
          <Button ref={button} aria-label="Scroll on top">
            <div>
              <AiOutlineArrowUp />
            </div>
            <div>
              <AiOutlineArrowUp />
            </div>
          </Button>
        </Area>
      </div>
    </Container>
  )
}
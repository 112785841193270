import styled from 'styled-components';

const Header = styled.header`
  position: absolute;
  z-index: var(--z-header);
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;

  nav {
    ul {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;

      li {
        overflow: hidden;

        button {
          position: relative;

          span {
            &.show { color: var(--text-muted); }

            &.hide {
              position: absolute;
              inset: 0;
              color: var(--text-primary);
            }

            &.word {
              display: inline-flex;
              align-items: center;
            }
          }
        }
      }
    }
  }

  svg {
    width: min(210px, 100%);
    height: 100%;

    path { pointer-events: none; }
  }
`

export { Header }
/**
 * Provides a function that, when executed, scrolls to a specified anchor element on the page.
 * This hook utilizes `useLenis` for smooth scrolling and `useWindowSize` to adjust scroll offset based on window width.
 * 
 * @returns {Function} scrollToAnchor - A function that takes an anchor (selector string) and an event object,
 * then scrolls to the target element. The event's default action is prevented.
 * 
 * @example
 * const scrollToAnchor = useScrollToAnchor()
 * // In your component's render method:
 * <a href="#targetElement" onClick={(e) => scrollToAnchor('#targetElement')(e)}>Go to Target</a>
 */
import { useCallback } from 'react'
import { useLenis } from '@studio-freight/react-lenis'
import { useWindowSize } from '@studio-freight/hamo'

export const useScrollToAnchor = () => {
  const lenis = useLenis()
  const { width: windowWidth } = useWindowSize()

  const scrollToAnchor = useCallback((anchor) => (e) => {
    e.preventDefault()

    if (anchor.charAt(0) !== '#') anchor = `#${anchor}`

    const target = document.querySelector(`${anchor}`)

    if (target) lenis.scrollTo(target, { offset: 0 })
  }, [lenis, windowWidth])

  return scrollToAnchor
}
import React, { useEffect, useRef } from 'react'
import { Anton, Nunito_Sans } from 'next/font/google'
import Link from 'next/link'
import { gsap } from 'gsap'
import { SmallTitle } from 'components/SmallTitle'
import { ImageAberration } from 'components/ImageAberration'
import { Section, List } from './Styles';

const anton = Anton({
  weight: ['400'],
  subsets: ['latin'],
})

const nunito_sans = Nunito_Sans({
  weight: ['400'],
  subsets: ['latin'],
})

export const Projects = ({ title, projects }) => {
  const liRef = useRef(null)

  useEffect(() => {
    const li = liRef.current.querySelectorAll('li')

    li.forEach((item) => {
      const title = item.querySelector('h3')
      const line = item.querySelector('.line')

      gsap.set(line, { width: 0 })
      gsap.to(line, {
        scrollTrigger: {
          trigger: line,
          start: 'top bottom',
          end: 'bottom bottom',
          scrub: 2,
        },
        width: '100%',
      })

      gsap.to(title, {
        backgroundSize: '100%',
        scrollTrigger: {
          trigger: title,
          start: 'top bottom',
          end: 'bottom 85%',
          scrub: true,
        },
      })

      const direction = item.classList.contains('right') ? 100 : -100

      gsap.set(item, {
        x: direction,
        opacity: 0,
      })

      gsap.to(item, {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: item,
          start: 'top bottom',
          end: 'bottom 90%',
          scrub: 1,
        },
      })
    })
  })

  return (
    <Section id='projets'>
      <div className='grid grid-cols-12 xl:grid-cols-24 gap-x-2.5'>
        <div className='col-start-2 col-end-5 xl:col-start-5 xl:col-end-10'>
          <SmallTitle title={title} level={'2'} />
        </div>
      </div>
      <List
        ref={liRef}
        className='grid grid-cols-12 xl:grid-cols-24 gap-x-2.5 xl:gap-16'
      >
        {projects.map((project, i) => {
          return (
            <li
              key={i}
              className={`col-start-2 col-end-12 ${i % 2 !== 0
                ? 'xl:col-start-9 xl:col-end-21 right'
                : 'xl:col-start-5 xl:col-end-17 left'
                } z-20`}
            >
              <div>
                <div className='tech'>
                  <div className='tech__img'>
                    <ImageAberration
                      imgSrc={project.image.url}
                      imgAlt={project.image.alt}
                      id={`work-${i}`}
                      cover
                    />
                  </div>
                  <div className='tech__head'>
                    <div className='tech__head-title'>
                      <h3 className={`${anton.className} upper text-3xl`}>{project.title}</h3>
                      <Link
                        aria-label={project.link.title}
                        target='_blank'
                        rel='noopener noreferrer'
                        href={project.link.url}
                        className={`${nunito_sans.className} seeMore`}
                      >
                        découvrir
                      </Link>
                    </div>
                    {project.collaboration && (
                      <span className={`${nunito_sans.className}`}>
                        Réalisé avec{' '}
                        <Link
                          href={project.collaboration.url}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          {project.collaboration.title}
                        </Link>
                      </span>
                    )}
                  </div>
                  <div className='tech__content'>
                    <span className={`${anton.className}`}>Technologies :</span>
                    <p className={`${nunito_sans.className}`}>{project.stack}</p>
                  </div>
                </div>
              </div>
              <div className='line'></div>
            </li>
          )
        })}
      </List>
    </Section>
  )
}
import styled from 'styled-components';

const Section = styled.section`
  padding: 6.25rem 0rem;
  overflow: hidden;
`

const List = styled.ul`
  margin-top: 1.25rem;

  .tech {
    display: flex;
    position: relative;
    flex-direction: column;
    padding: 1.875rem 0;

    @media screen and (min-width: 1024px) {
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    &__img {
      display: block;
      border-radius: 0.5rem;
      margin-bottom: 1.875rem;
      overflow: hidden;
      border: 1px solid var(--text-muted);
      height: 17.5rem;

      @media screen and (min-width: 768px) {
        width: 100%;
        height: 35rem;
      }

      @media screen and (min-width: 1024px) { height: 46rem; }

      @media screen and (min-width: 1280px) { height: 26vw; }
    }

    &__head {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;

      &-title {
        display: inline-flex;
        gap: 0.5rem;
        align-items: flex-end;

        h3 {
          color: rgba(255, 255, 255, 0.2);
          background: linear-gradient(to right, var(--text-primary), var(--text-primary))
            no-repeat;
          -webkit-background-clip: text;
          background-clip: text;
          background-size: 0%;
          transition: background-size cubic-bezier(0.1, 0.5, 0.5, 1) 0.5s;

          @media screen and (hover: hover) {
            position: relative;
            z-index: 3;
          }
        }

        a {
          letter-spacing: 0.075rem;
          margin-bottom: 0.35rem;
          white-space: nowrap;
          font-size: calc(10px + (12 - 10) * ((100vw - 320px) / (1440 - 320)));
          text-transform: uppercase;
        }
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      margin-top: 1.25rem;
      
      @media screen and (min-width: 1024px) {
        margin-top: 0;
        width: 20rem;
      }

      span {
        color: var(--text-primary);
        font-size: var(--font__title);
        letter-spacing: 0.075rem;
        text-transform: uppercase;
      }

      p {
        color: var(--text-muted);
        font-size: 0.75rem;
        letter-spacing: 0.075rem;

        @media screen and (hover: hover) {
          position: relative;
          z-index: 3;
          color: var(--text-muted);
          transition: color 0.5s ease-out;
        }
      }
    }

    a {
      color: var(--accent-color);

      @media screen and (hover: hover) {
        color: rgba(255, 99, 0, 0.8);
        background: linear-gradient(
            to right,
            var(--accent-color),
            var(--accent-color)
          )
          no-repeat;
        -webkit-background-clip: text;
        background-clip: text;
        background-size: 0%;
        transition: background-size cubic-bezier(0.1, 0.5, 0.5, 1) 0.5s;

        &:hover { background-size: 100%; }
      }
    }
  }

  .line {
    width: 100%;
    height: 0.0625rem;
    background-color: var(--background-secondary);
  }
`

export { Section, List }
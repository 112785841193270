import React, { useRef, useEffect } from 'react'
import { Anton, Maitree } from 'next/font/google'
import { ImageAberration } from 'components/ImageAberration';
import { gsap } from 'gsap'
import parse from 'html-react-parser'
import { Section } from './Styles';

const anton = Anton({
  weight: ['400'],
  subsets: ['latin'],
})

const maitree = Maitree({
  weight: ['200'],
  subsets: ['latin'],
})

export const Cover = ({ image, tags, content }) => {
  const aberrationRef = useRef(null)
  const titleRef = useRef(null)
  const firstBigTextRef = useRef(null)
  const secondBigTextRef = useRef(null)
  const thirdBigTextRef = useRef(null)
  const richTextRef = useRef(null)

  useEffect(() => {
    import('splitting').then(({ default: Splitting }) => {
      Splitting()

      const mm = gsap.matchMedia()
      const mediaQueries = [
        { minWidth: 320 },
        { minWidth: 640 },
        { minWidth: 1024 },
      ]

      mediaQueries.forEach(({ minWidth }) => {
        mm.add(`(min-width: ${minWidth}px)`, () => {
          if (minWidth === 320) {
            const tl = gsap.timeline()
            const charsRichText = richTextRef.current.querySelectorAll('.char')
            gsap.set(charsRichText, { opacity: 0 })
            gsap.set(aberrationRef.current, { opacity: 0 })

            tl.to(
              charsRichText,
              {
                opacity: 1,
                stagger: 0.03,
                duration: 1,
                ease: 'power4.out',
              },
              3
            ).to(
              aberrationRef.current,
              {
                opacity: 1,
                ease: 'sine.inOut',
                duration: 1,
              },
              '-=1'
            )
          }

          if (minWidth === 640) {
            const tl = gsap.timeline()
            const charsRichText = richTextRef.current.querySelectorAll('.char')
            const charsFirstBigText =
              firstBigTextRef.current.querySelectorAll('.char')
            const charsSecondBigText =
              secondBigTextRef.current.querySelectorAll('.char')

            gsap.set(charsRichText, { opacity: 0 })
            gsap.set(aberrationRef.current, { opacity: 0 })
            gsap.set([charsFirstBigText, charsSecondBigText], {
              y: 100,
              opacity: 0,
            })

            tl.to(
              charsRichText,
              {
                opacity: 1,
                stagger: 0.03,
                duration: 1,
                ease: 'power4.out',
              },
              3
            )
              .to(
                aberrationRef.current,
                {
                  opacity: 1,
                  ease: 'sine.inOut',
                  duration: 1,
                },
                '-=1'
              )
              .to(
                charsFirstBigText,

                {
                  y: 0,
                  opacity: 1,
                  stagger: 0.05,
                  duration: 2,
                  ease: 'power4.out',
                },
                '-=0.5'
              )
              .to(
                charsSecondBigText,
                {
                  y: 0,
                  opacity: 1,
                  stagger: 0.05,
                  duration: 2,
                  ease: 'power4.out',
                },
                '-=1.5'
              )
          }

          if (minWidth === 1024) {
            const tl = gsap.timeline()
            const charsRichText = richTextRef.current.querySelectorAll('.char')
            const charsFirstBigText =
              firstBigTextRef.current.querySelectorAll('.char')
            const charsSecondBigText =
              secondBigTextRef.current.querySelectorAll('.char')
            const charsThirdBigText =
              thirdBigTextRef.current.querySelectorAll('.char')

            gsap.set(charsRichText, { opacity: 0 })
            gsap.set(aberrationRef.current, { opacity: 0 })
            gsap.set(
              [charsFirstBigText, charsSecondBigText, charsThirdBigText],
              {
                y: 100,
                opacity: 0,
              }
            )

            tl.to(
              charsRichText,
              {
                opacity: 1,
                stagger: 0.03,
                duration: 1,
                ease: 'power4.out',
              },
              3
            )
              .to(
                aberrationRef.current,
                {
                  opacity: 1,
                  ease: 'sine.inOut',
                  duration: 1,
                },
                '-=1'
              )
              .to(
                charsFirstBigText,
                {
                  y: 0,
                  opacity: 1,
                  stagger: 0.05,
                  duration: 2,
                  ease: 'power4.out',
                },
                '-=0.5'
              )
              .to(
                charsSecondBigText,
                {
                  y: 0,
                  opacity: 1,
                  stagger: 0.05,
                  duration: 2,
                  ease: 'power4.out',
                },
                '-=1.5'
              )
              .to(
                charsThirdBigText,
                {
                  y: 0,
                  opacity: 1,
                  stagger: 0.05,
                  duration: 2,
                  ease: 'power4.out',
                },
                '-=1.5'
              )
          }
        })
      })
    })
  }, [])

  return (
    <Section className='b-cover'>
      <div className='aberration-container' ref={aberrationRef}>
        <ImageAberration imgSrc={image.url} imgAlt={image.alt} id={image.id} />
      </div>
      <div className='min-h-screen grid grid-cols-12 xl:grid-cols-24 gap-x-2.5 items-center'>
        <h1
          ref={titleRef}
          className='col-start-2 col-end-12 xl:col-start-3 xl:col-end-23 flex flex-col items-center b-cover__title content'
        >
          <span
            data-splitting='chars'
            ref={firstBigTextRef}
            className={`${anton.className} text-6xl hidden sm:block text-center text-border b-cover__title-border`}
          >
            {tags[0]}
          </span>
          <div className='flex flex-col lg:flex-row justify-center lg:gap-x-12 b-cover__title-texts items-center'>
            <div
              ref={richTextRef}
              data-splitting='chars'
              className={`${maitree.className} b-cover__title-text`}
            >
              {parse(content)}
            </div>
            <span
              data-splitting='chars'
              ref={secondBigTextRef}
              className={`${anton.className} text-6xl upper text-accent-color hidden sm:block text-center`}
            >
              {tags[1]}
            </span>
          </div>
          <span
            data-splitting='chars'
            ref={thirdBigTextRef}
            className={`${anton.className} hidden lg:block text-center text-6xl text-border b-cover__title-border`}
          >
            {tags[2]}
          </span>
        </h1>
      </div>
    </Section>
  )
}
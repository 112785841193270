import styled from 'styled-components';

const StyledFooter = styled.footer`
  position: relative;
  z-index: calc(var(--z-networks) + 1);
  padding: 6.25rem 0rem 1.875rem 0rem;

  .socials a {
    font-size: 2.25rem;
    letter-spacing: 0.09rem;
    color: rgba(255, 255, 255, 0.2);

    @media screen and (hover: hover) {
      color: rgba(255, 255, 255, 0.3);
      background: linear-gradient(to right, var(--text-primary), var(--text-primary)) no-repeat;
      -webkit-background-clip: text;
      background-clip: text;
      background-size: 0%;
      transition: background-size cubic-bezier(0.1, 0.5, 0.5, 1) 0.5s;

      &:hover {
        background-size: 100%;
      }
    }
  }

  .email {
    position: relative;
    overflow: hidden;
    margin-top: 1rem;

    @media screen and (min-width: 640px) { margin-top: 0; }

    .show,
    .hide { display: block; }

    .hide {
      position: absolute;
      bottom: 0;
      color: var(--text-primary);
    }
  }
`

export { StyledFooter }
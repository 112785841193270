import React, { useEffect, useRef } from 'react'
import { Anton } from 'next/font/google'
import { gsap } from 'gsap'

const anton = Anton({
  weight: ['400'],
  subsets: ['latin'],
})

export const SmallTitle = ({ title, level }) => {
  const charsTitleRefs = useRef(null)

  useEffect(() => {
    import('splitting').then(({ default: Splitting }) => {
      Splitting()

      const animateTitle = () => {
        if (charsTitleRefs.current) {
          const chars = charsTitleRefs.current.querySelectorAll('.char')

          gsap.fromTo(
            chars,
            {
              y: 100,
              opacity: 0,
            },
            {
              y: 0,
              opacity: 1,
              stagger: 0.05,
              duration: 2,
              ease: 'power4.out',
              scrollTrigger: {
                trigger: charsTitleRefs.current,
                start: 'top bottom',
                end: 'top 90%',
                scrub: 3,
              },
            }
          )
        }
      }

      animateTitle()
    })
  }, [])

  const HeadingTag = React.createElement(
    `h${level}`,
    {
      dangerouslySetInnerHTML: { __html: title },
      className: `${anton.className} text-sm upper text-text-primary clip`,
      ref: charsTitleRefs,
      'data-splitting': 'chars',
    }
  )

  return HeadingTag
}
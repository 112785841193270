import React, { useEffect } from 'react'
import Link from 'next/link'
import { gsap } from 'gsap'
import Image from 'next/image'
import { Container, List, Item } from './Styles'

export const SocialNetworks = ({ socials }) => {
  const animateSocialNetworks = () => {
    const items = gsap.utils.toArray('.social-networks__item')

    gsap.set(items, { x: -100, opacity: 0, pointerEvents: 'none' })

    gsap.to(items, {
      x: 0,
      opacity: 1,
      pointerEvents: 'all',
      stagger: 0.1,
      ease: 'sine.out',
      duration: 1,
      delay: 5,
    })

    items.forEach((item) => {
      gsap.fromTo(
        item,
        {
          y: 0,
          opacity: 1,
          pointerEvents: 'all',
        },
        {
          y: -100,
          opacity: 0,
          pointerEvents: 'none',
          scrollTrigger: {
            trigger: 'footer',
            start: 'top bottom',
            end: 'bottom top',
            scrub: 1,
          },
        }
      )
    })

    items.forEach((item) => {
      item.onmouseenter = function () {
        gsap.to(item, {
          duration: 0.3,
          ease: 'back.out(4)',
          scale: 1.1,
          rotate: 90,
        })
      }

      item.onmouseleave = function () {
        gsap.to(item, {
          duration: 0.3,
          ease: 'back.in(4)',
          scale: 1,
          rotate: 0,
        })
      }
    })
  }

  useEffect(() => {
    animateSocialNetworks()
  }, [])

  return (
    <Container className='hidden md:w-full md:grid md:grid-cols-24 gap-x-2.5'>
      <div className='flex justify-center items-end md:col-start-2 md:col-end-3'>
        <List className='social-networks'>
          {socials.map((item, i) => {
            return (
              <Item key={i}>
                <Link
                  className='social-networks__item'
                  href={item.link.url}
                  target='_blank'
                  aria-label={item.link.title}
                >
                  <Image
                    src={item.image.sourceUrl}
                    fill
                    className='object-contain'
                    sizes={item.image.sizes}
                    alt={item.image.altText}
                  />
                </Link>
              </Item>
            )
          })}
        </List>
      </div>
    </Container>
  )
}
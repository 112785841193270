import React, { useEffect, useRef } from 'react'
import { gsap } from 'gsap'
import Link from 'next/link'
import { FaLocationArrow } from 'react-icons/fa'
import { Anton, Maitree, Nunito_Sans } from 'next/font/google'
import { Atropos } from 'atropos/react'
import { Section, Container, Content, Area } from './Styles';

const anton = Anton({
  weight: ['400'],
  subsets: ['latin'],
})

const maitree = Maitree({
  weight: ['300'],
  subsets: ['latin'],
})

const nunitoSans = Nunito_Sans({
  weight: ['300'],
  subsets: ['latin'],
})

export const PreFooter = ({ title, firstText, secondText, suptitle, link }) => {
  const area = useRef(null)
  const button = useRef(null)
  const icon = useRef(null)
  const circle = useRef(null)
  const circle1 = useRef(null)
  const circle2 = useRef(null)

  const parallaxIt = (e, target, movement) => {
    const boundingRect = area.current.getBoundingClientRect()
    const relX = e.pageX - boundingRect.left
    const relY = e.pageY - boundingRect.top
    const scrollTop = window.scrollY || document.documentElement.scrollTop

    gsap.to(target.current, {
      x: (relX - boundingRect.width / 2) * movement,
      y: (relY - boundingRect.height / 2 - scrollTop) * movement,
      ease: 'power4.out',
      duration: 0.5,
    })
  }

  const callParallax = (e) => {
    if (button.current) parallaxIt(e, button, 0.5)
    if (icon.current) parallaxIt(e, icon, 0.05)
    if (circle.current) parallaxIt(e, circle, 0.01)
    if (circle1.current) parallaxIt(e, circle1, 0.1)
    if (circle2.current) parallaxIt(e, circle2, 0.2)
  }

  const parallaxRefs = [button, icon, circle, circle1, circle2]

  const resetParallax = () => {
    parallaxRefs.forEach((ref) => {
      if (ref.current) resetParallaxTween(ref)
    })
  }

  const resetParallaxTween = (ref) => {
    gsap.to(ref.current, {
      x: 0,
      y: 0,
      duration: 0.5,
      ease: 'back',
    })
  }

  useEffect(() => {
    area.current.addEventListener('mousemove', callParallax)
    area.current.addEventListener('mouseleave', resetParallax)
  })

  useEffect(() => {
    gsap.set('.atropos', { opacity: 0, scale: 0, filter: 'blur(50px)' })

    gsap.to('.atropos', {
      opacity: 1,
      scale: 1,
      filter: 'blur(0px)',
      ease: 'linear',
      scrollTrigger: {
        trigger: '.atropos',
        start: 'top bottom',
        end: 'top 75%',
        scrub: 2,
        once: true,
      },
    })
  })

  return (
    <Section id='contact'>
      <div className='min-h-screen grid grid-cols-12 xl:grid-cols-24 gap-x-2.5'>
        <div className='min-h-full col-start-2 col-end-12 xl:col-start-3 xl:col-end-23 flex items-center'>
          <Atropos
            className='atropos'
            activeOffset={25}
            shadow={false}
            rotateTouch={false}
          >
            <Container>
              <Content className='content'>
                <h2>
                  <span className={`${maitree.className}`}>
                    {suptitle}
                  </span>
                  <span
                    className={`${anton.className}`}
                    data-atropos-offset='5'
                  >
                    {title}
                  </span>
                </h2>
                <Area ref={area}>
                  <Link
                    ref={button}
                    className='button'
                    href={link.url}
                    aria-label={link.title}
                  >
                    <div className='button__circle' ref={circle}></div>
                    <div className='button__circle' ref={circle1}></div>
                    <div className='button__circle' ref={circle2}></div>
                    <div className={`${nunitoSans.className} button__text`}>
                      <p>{firstText}</p>
                      <p>{secondText}</p>
                    </div>
                    <div className='button__icon' ref={icon}>
                      <FaLocationArrow />
                    </div>
                  </Link>
                </Area>
              </Content>
            </Container>
          </Atropos>
        </div>
      </div>
    </Section>
  )
}
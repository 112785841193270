import styled from 'styled-components';

const Section = styled.section`
  position: relative;

  .word {
    display: inline-flex;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }

  .aberration-container {
    display: block;
    position: absolute;
    z-index: calc(var(--z-networks) - 1);
    inset: 0;
    overflow: hidden;
    mix-blend-mode: color-dodge;

    div {
      margin-inline: auto;
      width: 100%;
      height: 100%;
    }
  }

  .b-cover__title {
    position: relative;
    z-index: 2;
    line-height: normal;
    pointer-events: none;

    &-texts {
      div { width: min(66rem, 100%); }

      .upper { clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 80%); }
    }

    &-text {
      font-size: clamp(2rem, 5vw, 3rem);
      font-style: normal;
      line-height: normal;

      @media screen and (min-width: 640px) { text-align: center; }

      @media screen and (min-width: 1024px) { text-align: right; }

      strong { color: var(--accent-color); }
    }

    &-border {
      clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 80%);

      @media screen and (min-width: 1024px) {
        &:first-child { margin-bottom: -5rem; }

        &:last-child { margin-top: -5rem; }
      }

    }
  }
`

export { Section }
import { BlockRenderer } from 'components/BlockRenderer';
import { Menu } from 'components/Menu';
import { Footer } from 'components/Footer';
import { PageWrapper } from 'context/page';
import Head from 'next/head';
import { SmoothScrolling } from "components/SmoothScrolling";
import { FlareCursor } from 'components/FlareCursor';
import { SocialNetworks } from 'components/SocialNetworks';
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export const Page = (props) => {
  // console.log('PAGE PROPS: ', props);
  return (
    <SmoothScrolling>
      <PageWrapper value={{ title: props.title, featuredImage: props.featuredImage }}>
        <Head>
          <title>{props.seo.title}</title>
          <meta name="description" content={props.seo.metaDesc} />
          <meta name="theme-color" content="#16161a" />
          <link rel="shortcut icon" href="/favicon.ico" />
          {props.favicon?.length > 0 &&
            props.favicon.map(({ width, sourceUrl }) => {
              if (width === '180') {
                return (
                  <link
                    key={`fav-${width}x${width}`}
                    rel="apple-touch-icon"
                    href={sourceUrl}
                    sizes={`${width}x${width}`}
                  />
                );
              }
              return (
                <link
                  key={`fav-${width}x${width}`}
                  rel="icon"
                  type="image/png"
                  sizes={`${width}x${width}`}
                  href={sourceUrl}
                />
              );
            })}
          <link rel="manifest" href="/manifest.json" />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:url" content="https://www.bechuetassocies.com" />
          <meta name="twitter:title" content={props.seo.title} />
          <meta name="twitter:description" content={props.seo.metaDesc} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={props.seo.title} />
          <meta property="og:description" content={props.seo.metaDesc} />
          <meta property="og:site_name" content={props.seo.title} />
          <meta property="og:url" content="https://www.bechuetassocies.com" />
        </Head>
        <FlareCursor />
        <Menu />
        <main>
          <SocialNetworks socials={props.options.socials} />
          <BlockRenderer blocks={props.blocks} />
        </main>
        <Footer
          links={props.footer.links}
          mail={props.footer.mail}
          title={props.footer.title}
        />
      </PageWrapper>
    </ SmoothScrolling>
  )
}
import styled from 'styled-components';

const Container = styled.div`
  position: fixed;
  bottom: 5vh;
  left: 0;
  z-index: var(--z-networks);
`

const List = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.875rem;
`

const Item = styled.li`
  position: relative;
  display: grid;
  place-items: center;
  flex-shrink: 0;
  overflow: hidden;
  width: 1.25rem;
  height: 1.25rem;

  a {
    position: absolute;
    inset: 0;
  }
`

export { Container, List, Item }
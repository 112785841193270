import React, { useEffect, useRef } from 'react'
import { Anton, Nunito_Sans } from 'next/font/google'
import { SmallTitle } from 'components/SmallTitle'
import { gsap } from 'gsap'
import { Section, List } from './Styles';

const anton = Anton({
  weight: ['400'],
  subsets: ['latin'],
})

const nunitoSans = Nunito_Sans({
  weight: ['600'],
  subsets: ['latin'],
})

export const TitleItems = ({ title, items }) => {
  const listRef = useRef(null)

  const animateListItem = (item) => {
    const chars = item.querySelectorAll('.char')
    const titles = item.querySelectorAll('h3')
    const tl = gsap.timeline()

    item.addEventListener('mouseenter', () => {
      tl.clear()
      tl.set(chars, { y: 5, rotateX: -90, opacity: 0 })

      gsap.to(titles, {
        x: 20,
        duration: 0.5,
        ease: 'sine.out',
      })

      tl.to(chars, {
        y: 0,
        rotateX: 0,
        opacity: 1,
        stagger: 0.02,
        ease: 'power4.out',
      })

      tl.play()
    })

    item.addEventListener('mouseleave', () => {
      gsap.to(titles, {
        x: 0,
        duration: 0.5,
        ease: 'sine.out',
      })

      tl.reverse()
    })
  }

  useEffect(() => {
    import('splitting').then(({ default: Splitting }) => {
      Splitting()

      const listItems = listRef.current.querySelectorAll('li')

      listItems.forEach((item) => {
        const line = item.querySelector('.line')
        const title = item.querySelector('h3')

        gsap.set(line, { width: 0 })
        gsap.to(line, {
          scrollTrigger: {
            trigger: line,
            start: 'top bottom',
            end: 'bottom 80%',
            scrub: 2,
          },
          width: '100%',
        })

        gsap.set(item, { opacity: 0 })

        gsap.to(item, {
          scrollTrigger: {
            trigger: item,
            start: 'top bottom',
            end: 'bottom 80%',
            scrub: 2,
          },
          opacity: 1,
        })

        gsap.to(title, {
          backgroundSize: '100%',
          scrollTrigger: {
            trigger: title,
            start: 'top bottom',
            end: 'bottom 75%',
            scrub: true,
          },
        })
      })

      listItems.forEach(animateListItem)
    })
  })

  return (
    <Section id='a-propos'>
      <div className='grid grid-cols-12 xl:grid-cols-24 gap-x-2.5'>
        <div className='col-start-2 col-end-5 xl:col-start-5 xl:col-end-10'>
          <SmallTitle title={title} level={'2'} />
        </div>
      </div>
      <List ref={listRef}>
        {items?.map((item, i) => {
          return (
            <li key={i} className='flareBigger'>
              <div className='background'></div>
              {i === 0 ? (
                <>
                  <span className='line bg-background-secondary'></span>
                  <div className='grid grid-cols-12 xl:grid-cols-24 gap-x-2.5'>
                    <div className='col-start-2 col-end-12 xl:col-start-5 xl:col-end-21 flex flex-col sm:flex-row sm:items-center justify-between gap-x-2.5'>
                      <h3 className={`${anton.className} text-5xl upper`}>{item.title}</h3>
                      <p
                        data-splitting='chars'
                        className={`${nunitoSans.className} text-sm`}
                      >
                        {item.text}
                      </p>
                    </div>
                  </div>
                  <span className='line bg-background-secondary'></span>
                </>
              ) : (
                <>
                  <div className='grid grid-cols-12 xl:grid-cols-24 gap-x-2.5'>
                    <div className='col-start-2 col-end-12 xl:col-start-5 xl:col-end-21 flex flex-col sm:flex-row sm:items-center justify-between gap-x-2.5'>
                      <h3 className={`${anton.className} text-5xl upper`}>{item.title}</h3>
                      <p
                        data-splitting='chars'
                        className={`${nunitoSans.className} text-sm`}
                      >
                        {item.text}
                      </p>
                    </div>
                  </div>
                  <span className='line bg-background-secondary'></span>
                </>
              )}
            </li>
          )
        })}
      </List>
    </Section>
  )
}